import React from 'react';

const ModelScoreBarChart = ({ scores }) => {
    const selectedScores = [
        { key: 'overall', label: 'Overall performance' },
        { key: 'hardPrompts', label: 'Difficult prompts' },
        { key: 'instructionFollowing', label: 'Following instructions' },
        { key: 'coding', label: 'Coding tasks' },
        { key: 'math', label: 'Math tasks' },
        { key: 'multiTurn',  label: 'Long conversations'}
    ];
    const minScore = 700;
    const maxScore = 1400;

    return (
        <div className="mt-2 space-y-2">
            {selectedScores.map(({ key, label }) => (
                <div key={key} className="flex flex-col sm:flex-row sm:items-center text-xs sm:text-sm">
                    <span className="w-full sm:w-40 text-text mb-1 sm:mb-0" title={label}>{label}</span>
                    <div className="flex-grow flex items-center">
                        <div className="flex-grow bg-gray-200 rounded-full h-2 relative">
                            <div
                                className="bg-primary h-2 rounded-full"
                                style={{ width: `${((scores[key] - minScore) / (maxScore - minScore)) * 100}%` }}
                            ></div>
                        </div>
                        <span className="ml-2 w-16 text-right whitespace-nowrap">
                            <span className="font-bold text-primary">{scores[key]}</span>
                            <span className="text-gray-500">/1400</span>
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ModelScoreBarChart;
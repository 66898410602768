import SwitchModelButton from './SwitchModelButton';
import NewChatButton from './NewChatButton';
import ChatHistory from './ChatHistory';

const SideBar = ({ 
  isMinimized, 
  setIsMinimized, 
  selectedModel, 
  setSelectedModel, 
  setMessages, 
  firstMessageSent, 
  setFirstMessageSent, 
  user, 
  conversations, 
  setConversations, 
  conversationId,
  setConversationId, 
  darkMode, 
  visibleDetail, 
  setVisibleDetail, 
  showModelsPopup, 
  setShowModelsPopup 
}) => {

  return (
    <div className={`fixed top-0 left-0 h-screen bg-secondary dark:bg-secondary-dark shadow-lg transition-all duration-100 ${isMinimized ? 'w-16' : 'w-64'}`}>
      <div className="flex flex-col h-full">
        <div className="flex flex-col items-start p-3">
          <div className={`flex items-center whitespace-nowrap overflow-hidden ${isMinimized ? 'w-10' : 'w-full'}`}>
            <span className="text-2xl p-2" aria-label="Strawberry">🍓</span>
            <span className={`text-2xl transition-opacity duration-100 ${isMinimized ? 'opacity-0' : 'opacity-100'}`}>
              Strawberry
            </span>
          </div>
          <div className="h-3"></div> 
          <button
            onClick={() => setIsMinimized(!isMinimized)}
            className={`p-2 rounded-full  transition-colors text-sm hover:bg-background dark:hover:bg-hover-dark ${
              isMinimized 
                  ? 'w-10' 
                  : 'w-44 text-left'
              }`
            }
            aria-label={isMinimized ? "Expand sidebar" : "Collapse sidebar"}
          >
            <div className={`flex items-center ${isMinimized ? 'justify-center' : 'justify-start'}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#7cb342"
                strokeWidth={2} 
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {!isMinimized && <span className="ml-2 text-text dark:text-text-dark">Hide sidebar</span>}
            </div>
          </button>
          <div className="h-2"></div> 
          <NewChatButton
            setMessages={setMessages}
            firstMessageSent={firstMessageSent}
            setFirstMessageSent={setFirstMessageSent}
            isMinimized={isMinimized}
            setConversationId={setConversationId}
          />
          <div className="h-2"></div> 
          <SwitchModelButton
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            isMinimized={isMinimized}
            firstMessageSent={firstMessageSent}
            darkMode={darkMode}
            visibleDetail={visibleDetail}
            setVisibleDetail={setVisibleDetail}
            showModelsPopup={showModelsPopup}
            setShowModelsPopup={setShowModelsPopup}
          />
        </div>
          {!isMinimized && 
          <div className="flex-1 overflow-hidden">
            <ChatHistory
              user={user}
              setMessages={setMessages}
              setFirstMessageSent={setFirstMessageSent}
              conversations={conversations}
              setConversations={setConversations}
              conversationId={conversationId}
              setConversationId={setConversationId}
              setSelectedModel={setSelectedModel}
            />
          </div>
          }
      </div>
    </div>
  );
};

export default SideBar;
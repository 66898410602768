import React, {useEffect, useState, useRef} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from './components/Logo';
import ModelOptions from './components/ModelOptions';
import Header from './components/Header';
import InputContainer from './components/InputContainer';
import SideBar from './components/SideBar';
import LoginPage from './components/LoginPage';
import ChatArea from './components/ChatArea';

function App () {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [firstMessageSent, setFirstMessageSent] = useState(false);
    const [messages, setMessages] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [streamedResponse, setStreamedResponse] = useState('');
    const [streaming, setStreaming] = useState(false);
    const [isMinimized, setIsMinimized] = useState(true);
    const [darkMode, setDarkMode] = useState(() => { return localStorage.getItem('darkMode') === 'true' });
    const chatEnd = useRef(null);
    const [conversations, setConversations] = useState([]);
    const [conversationId, setConversationId] = useState(null);
    const [showModelsPopup, setShowModelsPopup] = useState(false);
    const [visibleDetail, setVisibleDetail] = useState({});
    const [creditsBalance, setCreditsBalance] = useState(null);

    useEffect(() => {
        if (chatEnd.current) {
            chatEnd.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
      if (darkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
      localStorage.setItem('darkMode', darkMode);
    }, [darkMode]);
  
    const toggleDarkMode = () => {
      setDarkMode(!darkMode);
    };

    useEffect(() => {
      if (isAuthenticated && user) {
        const sendUserInfo = async () => {
          try {
            const token = await getAccessTokenSilently();
            await fetch(`${process.env.REACT_APP_API_URL}/user`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                sub: user.sub,
                name: user.name,
                email: user.email
              })
            });
          } catch (error) {
            console.error('Error sending user info:', error);
          }
        };
        sendUserInfo();
      }
    }, [isAuthenticated, user, getAccessTokenSilently]);

    if (!isAuthenticated) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-background dark:bg-background-dark">
                <LoginPage />
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-background dark:bg-background-dark text-text dark:text-text-dark">
          <div className="relative z-50">
            <SideBar 
              isMinimized={isMinimized}
              setIsMinimized={setIsMinimized}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
              setMessages={setMessages}
              firstMessageSent={firstMessageSent}
              setFirstMessageSent={setFirstMessageSent}
              user={user}
              conversations={conversations}
              setConversations={setConversations}
              conversationId={conversationId}
              setConversationId={setConversationId}
              darkMode={darkMode}
              visibleDetail={visibleDetail}
              setVisibleDetail={setVisibleDetail}
              showModelsPopup={showModelsPopup}
              setShowModelsPopup={setShowModelsPopup}
            />
          </div>
          <div className={`flex-1 flex flex-col ${isMinimized ? 'ml-16' : 'ml-60'}`}>
            <Header 
              className="h-[5vh]"
              darkMode={darkMode}
              toggleDarkMode={toggleDarkMode}
              creditsBalance={creditsBalance}
              setCreditsBalance={setCreditsBalance}
            />
            <div className="flex-1 flex flex-col min-h-0 max-w-[1200px] mx-auto w-full">
              {!firstMessageSent ? (
                <>
                  <div className="flex justify-center items-center w-full h-full max-h-[80vh]">
                    <Logo />
                  </div>
                  <div className="w-full h-[7vh] flex items-end pt-2">
                    <ModelOptions 
                      selectedModel={selectedModel} 
                      setSelectedModel={setSelectedModel} 
                      darkMode={darkMode}
                      visibleDetail={visibleDetail}
                      setVisibleDetail={setVisibleDetail}
                      user={user}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="h-full max-h-[87vh] overflow-y-auto">
                    <ChatArea
                      user={user}
                      selectedModel={selectedModel}
                      messages={messages} 
                      chatEnd={chatEnd}
                      streamedResponse={streamedResponse}
                      streaming={streaming}
                      darkMode={darkMode}
                    />
                  </div>
                </>
              )}
              <div className="w-full p-1 flex-shrink-0">
                <InputContainer 
                  messages={messages} 
                  setMessages={setMessages} 
                  firstMessageSent={firstMessageSent}
                  setFirstMessageSent={setFirstMessageSent}
                  selectedModel={selectedModel}
                  setStreamedResponse={setStreamedResponse}
                  streaming={streaming}
                  setStreaming={setStreaming}
                  user={user}
                  darkMode={darkMode}
                  conversationId={conversationId}
                  setConversationId={setConversationId}
                  setConversations={setConversations}
                  creditsBalance={creditsBalance}
                  setCreditsBalance={setCreditsBalance}
                />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
    );
};

export default App;
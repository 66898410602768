import React, { useRef, useEffect, useMemo, useState } from 'react';
import { getModelInfo } from './ModelData';

const ModelsPopup = ({ children, onClose, visibleDetail, setVisibleDetail, darkMode, selectedModel, setSelectedModel }) => {
    const popupRef = useRef(null);
    const modelInfo = useMemo(() => getModelInfo(darkMode), [darkMode]);
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setTimeout(() => {
                    onClose();
                }, 10);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const sortedAllOptions = useMemo(() => {
        return [...modelInfo].sort((a, b) => {
            if (sortBy === 'name') {
                return sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            } else if (sortBy === 'cost') {
                return sortOrder === 'asc' ? a.cost - b.cost : b.cost - a.cost;
            } else if (sortBy === 'speed') {
                return sortOrder === 'asc' ? a.speed - b.speed : b.speed - a.speed;
            }
            return 0;
        });
    }, [modelInfo, sortBy, sortOrder]);

    const handleSort = (newSortBy) => {
        if (newSortBy === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(newSortBy);
            setSortOrder('asc');
        }
    };

    const toggleDetail = (modelId) => {
        setVisibleDetail(prev => ({
            ...prev,
            [modelId]: !prev[modelId]
        }));
    };

    const toggleAllDetails = () => {
        const allExpanded = modelInfo.every(model => visibleDetail[model.id]);
        setVisibleDetail(prev => {
            const newState = {};
            modelInfo.forEach(model => {
                newState[model.id] = !allExpanded;
            });
            return newState;
        });
    };

    const allExpanded = modelInfo.every(model => visibleDetail[model.id]);
    
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4">
            <div ref={popupRef} className="w-full max-w-2xl h-full max-h-[90vh] flex flex-col bg-white dark:bg-secondary-dark rounded-lg shadow-lg border border-border dark:border-border-dark">
                <div className="flex-shrink-0 px-4 sm:px-6 pt-4 pb-2">
                    <div className="flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                        <h2 className="text-lg font-semibold mr-4">Select model</h2>
                        <div className="flex space-x-2 border border-border dark:border-border-dark p-1 rounded-lg">
                            {['Name', 'Cost', 'Speed'].map((option) => (

                                <button
                                    key={option}
                                    onClick={() => handleSort(option.toLowerCase())}
                                    className={`flex items-center justify-center text-xs sm:text-sm p-1 w-16 sm:w-20 rounded-md transition-all duration-300 ease-in-out hover:bg-hover dark:hover:bg-hover-dark ${
                                        sortBy === option.toLowerCase()
                                            ? 'text-primary dark:text-primary'
                                            : 'text-text dark:text-text-dark hover:text-primary-dark'
                                    }`}
                                >
                                    {option}
                                    <svg className="w-3 h-3 sm:w-4 sm:h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="#7cb342" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        {sortBy === option.toLowerCase() ? (
                                            <path d={sortOrder === 'asc' ? "M18 15l-6-6-6 6" : "M6 9l6 6 6-6"} />
                                        ) : (
                                            <line x1="5" y1="12" x2="19" y2="12" />
                                        )}
                                    </svg>
                                </button>
                            ))}
                        </div>
                        <div className="flex space-x-2 border border-border dark:border-border-dark p-1 rounded-lg">
                                <button 
                                    onClick={toggleAllDetails}
                                    className="flex items-center justify-center text-xs sm:text-sm text-text dark:text-text-dark hover:text-primary-dark p-1 w-24 sm:w-28 rounded-md transition-all duration-300 ease-in-out hover:bg-hover dark:hover:bg-hover-dark"
                                >
                                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="#7cb342" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        {allExpanded ? (
                                            <path d="M5 12h14" />
                                        ) : (
                                            <>
                                                <path d="M12 5v14" />
                                                <path d="M5 12h14" />
                                            </>
                                        )}
                                    </svg>
                                    <span className="flex-grow">
                                        {allExpanded ? 'Collapse All' : 'Expand All'}
                                    </span>
                                </button>
                        </div> 
                    </div>
                </div>
                <div className="flex-grow overflow-y-auto px-6 py-2">
                    {sortedAllOptions.map((model) => (
                        React.cloneElement(children, {
                            key: model.id,
                            model: model,
                            selectedModel: selectedModel,
                            setSelectedModel: setSelectedModel,
                            isPopup: true,
                            isDetail: visibleDetail[model.id] || false,
                            toggleDetail: () => toggleDetail(model.id)
                        })
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ModelsPopup;
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { askAi } from '../chatService';
import { EditorState, Plugin } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { Schema } from 'prosemirror-model'
import { schema as basicSchema } from 'prosemirror-schema-basic'
import { keymap } from 'prosemirror-keymap'
import { toggleMark, baseKeymap } from 'prosemirror-commands'
import { history, undo, redo } from 'prosemirror-history'
import { addListNodes, wrapInList, splitListItem, liftListItem, sinkListItem } from 'prosemirror-schema-list'
import { inputRules, wrappingInputRule } from 'prosemirror-inputrules'
import Tooltip from './Tooltip'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import mammoth from 'mammoth';
import Papa from 'papaparse';
import { getModelInfo } from './ModelData';

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.mjs`;

const mySchema = new Schema({
    nodes: addListNodes(basicSchema.spec.nodes, 'paragraph block*', 'block'),
    marks: basicSchema.spec.marks
});

const unorderedListRule = wrappingInputRule(/^\s*([-*])\s$/, mySchema.nodes.bullet_list);
const orderedListRule = wrappingInputRule(/^\s*(\d+)\.\s$/, mySchema.nodes.ordered_list);

const InputContainer = ({ messages, setMessages, firstMessageSent, setFirstMessageSent, selectedModel, setStreamedResponse, streaming, setStreaming, user, darkMode, conversationId, setConversationId, setConversations, creditsBalance, setCreditsBalance }) => {
    const [input, setInput] = useState('');
    const [uploadedImages, setUploadedImages] = useState([]);
    const inputRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const dragCounter = useRef(0);
    const editorViewRef = useRef(null);
    const editorRef = useRef(null);
    const submitButtonRef = useRef(null);
    const [attachedPdfs, setAttachedPdfs] = useState([]);
    const [attachedDocx, setAttachedDocx] = useState([]);
    const [attachedCsvs, setAttachedCsvs] = useState([]);
    const [attachedTxts, setAttachedTxts] = useState([]);
    const [attachedXlsx, setAttachedXlsx] = useState([]);
    const [attachedJsons, setAttachedJsons] = useState([]);

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB limit for images
    const MAX_IMAGES = 5;

    const MAX_PDF_SIZE = 30 * 1024 * 1024; // 30MB limit for PDFs
    const MAX_PDFS = 5;

    const MAX_DOCX_SIZE = 30 * 1024 * 1024; // 30MB limit for DOCX files
    const MAX_DOCX = 5;

    const MAX_TXT_SIZE = 30 * 1024 * 1024; // 30MB limit for TXT files
    const MAX_TXT = 5;

    const MAX_CSV_SIZE = 30 * 1024 * 1024; // 30MB limit for CSV files 
    const MAX_CSV = 5;

    const MAX_XLSX_SIZE = 30 * 1024 * 1024; // 30MB limit for XLSX files
    const MAX_XLSX = 5;

    const MAX_JSON_SIZE = 30 * 1024 * 1024; // 30MB limit for JSON files
    const MAX_JSON = 5;

    useEffect(() => {
        if (editorViewRef.current) editorViewRef.current.focus();
    }, [editorViewRef]);

    const promptEditor = (element) => {
        if (element && !editorViewRef.current) {
          const view = new EditorView(element, {
            state: EditorState.create({
              schema: mySchema,
              plugins: [
                history(),
                inputRules({rules: [unorderedListRule, orderedListRule]}),
                keymap({
                    'Shift-Enter': (state, dispatch) => {
                        if (splitListItem(mySchema.nodes.list_item)(state, dispatch)) return true;
                        if (dispatch) {
                            dispatch(state.tr.replaceSelectionWith(mySchema.nodes.hard_break.create()));
                        }
                        return true;
                    },
                    'Enter': (state, dispatch) => {
                        if (submitButtonRef.current && !submitButtonRef.current.disabled) {
                            submitButtonRef.current.click();
                        }
                        return true;
                    },
                    'Mod-b': toggleMark(mySchema.marks.strong),
                    'Mod-i': toggleMark(mySchema.marks.em),
                    'Mod-z': undo,
                    'Mod-y': redo,
                    'Mod-Shift-z': redo,
                    'Mod-Shift-8': wrapInList(mySchema.nodes.bullet_list),
                    'Mod-Shift-9': wrapInList(mySchema.nodes.ordered_list),
                    'Tab': sinkListItem(mySchema.nodes.list_item),
                    'Shift-Tab': liftListItem(mySchema.nodes.list_item),
                }),
                keymap(baseKeymap),
                pastePlugin(handleFileInput)
              ]
            }),
            dispatchTransaction(transaction) {
                let newState = view.state.apply(transaction);
                view.updateState(newState);
                const text = newState.doc.textContent;
                setInput(text);
                adjustEditorHeight();
            },
          });
          editorViewRef.current = view;
        }
    };

    const adjustEditorHeight = () => {
        if (editorRef.current) {
            editorRef.current.style.height = 'auto';
            editorRef.current.style.height = `${editorRef.current.scrollHeight}px`;
        }
    };

    const handleFileInput = async (items) => {
        const files = Array.from(items).map(item => {
            if (item instanceof File) return item;
            if (item.getAsFile) return item.getAsFile();
            return null;
        }).filter(file => file && (file.type.startsWith('image/') || file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'text/csv' || file.type === 'text/plain' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/json' ));

        const availableImageSlots = MAX_IMAGES - uploadedImages.length;
        const availablePdfSlots = MAX_PDFS - attachedPdfs.length;
        const availableDocxSlots = MAX_DOCX - attachedDocx.length;
        const availableTxtSlots = MAX_TXT - attachedTxts.length;
        const availableCsvSlots = MAX_CSV - attachedCsvs.length;
        const availableXlsxSlots = MAX_XLSX - attachedXlsx.length;
        const availableJsonSlots = MAX_JSON - attachedJsons.length;

        const newImages = files.filter(file => file.type.startsWith('image/')).slice(0, availableImageSlots);
        const newPdfs = files.filter(file => file.type === 'application/pdf').slice(0, availablePdfSlots);
        const newDocx = files.filter(file => file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document').slice(0, availableDocxSlots);
        const newTxts = files.filter(file => file.type === 'text/plain').slice(0, availableTxtSlots);
        const newCsvs = files.filter(file => file.type === 'text/csv').slice(0, availableCsvSlots);
        const newXlsx = files.filter(file => file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel').slice(0, availableXlsxSlots);
        const newJsons = files.filter(file => file.type === 'application/json').slice(0, availableJsonSlots);

        if (files.length > availableImageSlots + availablePdfSlots + availableDocxSlots + availableTxtSlots + availableCsvSlots + availableXlsxSlots + availableJsonSlots) {
            toast.warning(`Cannot attach more than ${MAX_IMAGES} images, ${MAX_PDFS} PDFs, ${MAX_DOCX} DOCXs, ${MAX_TXT} TXTs, ${MAX_CSV} CSVs, ${MAX_XLSX} XLSXs, and ${MAX_JSON} JSONs`);
        }

        // Handle images
        newImages.forEach(file => {
            if (file.size > MAX_FILE_SIZE) {
                toast.error(`${file.name} exceeds the image file limit of 5MB`);
            } else {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setUploadedImages(prev => [...prev, reader.result]);
                };
                reader.readAsDataURL(file);
            }
        });

        // Handle PDF files
        newPdfs.forEach(file => {
            if (file.size > MAX_PDF_SIZE) {
                toast.error(`${file.name} exceeds the PDF file limit of 10MB`);
            } else {
                setAttachedPdfs(prev => [...prev, file]);
            }
        });

        // Handle DOCX files
        newDocx.forEach(file => {
            if (file.size > MAX_DOCX_SIZE) {
                toast.error(`${file.name} exceeds the DOCX file limit of 10MB`);
            } else {
                setAttachedDocx(prev => [...prev, file]);
            }
        });

        // Handle TXT files
        newTxts.forEach(file => {
            if (file.size > MAX_TXT_SIZE) {
                toast.error(`${file.name} exceeds the TXT file limit of 10MB`);
            } else {
                setAttachedTxts(prev => [...prev, file]);
            }
        });

        // Handle CSV files
        newCsvs.forEach(file => {
            if (file.size > MAX_CSV_SIZE) {
              toast.error(`${file.name} exceeds the CSV file limit of 10MB`);
            } else {
              setAttachedCsvs(prev => [...prev, file]);
            }
        });

        // Handle XLSX files
        newXlsx.forEach(file => {
            if (file.size > MAX_XLSX_SIZE) {
                toast.error(`${file.name} exceeds the XLSX file limit of 10MB`);
            } else {
                setAttachedXlsx(prev => [...prev, file]);
            }
        });

        // Handle JSON files
        newJsons.forEach(file => {
            if (file.size > MAX_JSON_SIZE) {
                toast.error(`${file.name} exceeds the JSON file limit of 10MB`);
            } else {
                setAttachedJsons(prev => [...prev, file]);
            }
        });

        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current === 0) {
            setIsDragging(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileInput(e.dataTransfer.files);
        }
    };

    const pastePlugin = () => {
        return new Plugin({
            props: {
                handlePaste(view, event) {
                    const items = event.clipboardData.items;
                if (items && items.length > 0) {
                    let hasHandledPaste = false;

                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        if (item.type.indexOf('image') !== -1 || 
                            item.type === 'application/pdf' || 
                            item.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || 
                            item.type === 'text/csv' || 
                            item.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                            item.type === 'application/json') {
                            event.preventDefault();
                            handleFileInput([item]);
                            hasHandledPaste = true;
                        } else if (item.type === 'text/plain' && item.kind === 'file') {
                            event.preventDefault();
                            handleFileInput([item]);
                            hasHandledPaste = true;
                        }
                    }
                    if (!hasHandledPaste) {
                        return false;
                    }
                    return true;
                }
                return false;
            }
        }
    });
};

    const createMessageContent = (text, images, pdfTexts, docxTexts, csvTexts, txtTexts, xlsxTexts, jsonTexts) => {
        const content = [];
        if (text) content.push({ type: 'text', text });
        images.forEach(image => {
            const imageContent = { type: 'image_url', image_url: { url: image } };
            content.push(imageContent);
        });
        pdfTexts.forEach(pdfText => {
            content.push({type: 'text', text: `PDF file content: ${pdfText}`});
        });
        docxTexts.forEach(docxText => {
            content.push({type: 'text', text: `DOCX file content: ${docxText}`});
        });
        txtTexts.forEach(txtText => {
            content.push({type: 'text', text: `TXT file content: ${txtText}`});
        });
        csvTexts.forEach(csvText => {
            content.push({type: 'text', text: `CSV file content: ${csvText}`});
        });
        xlsxTexts.forEach(xlsxText => {
            content.push({type: 'text', text: `XLSX file content: ${xlsxText}`});
        });
        jsonTexts.forEach(jsonText => {
            content.push({type: 'text', text: `JSON file content: ${jsonText}`});
        });
        return content; 
    };

    const formatParagraph = (node) => {
        let text = '';
        node.forEach(inline => {
            if (inline.type.name === 'hard_break') {
                text += '  \n';
            } else {
                let content = inline.text;
                inline.marks.forEach(mark => {
                    if (mark.type.name === 'strong') {
                        content = `**${content}**`;
                    } else if (mark.type.name === 'em') {
                        content = `*${content}*`;
                    }
                });
                text += content;
            }
        });
        return text;
    };

    const handleSendMessage = async () => { 
        try {
                const models = getModelInfo().sort((a, b) => a.cost - b.cost);
                const selectedModelCost = models.find(model => model.id === selectedModel)?.cost || 0;
                
                if (selectedModelCost > creditsBalance) {
                    const affordableModels = models.filter(model => model.cost <= creditsBalance);
                    const errorMessage = affordableModels.length > 0
                        ? `Looks like you don't have enough credits. Select a model with a lower cost (${affordableModels.map(m => m.name).join(', ')}), wait until tomorrow or upgrade your membership`
                        : "Looks like you don't have enough credits. Wait until tomorrow or upgrade your membership";
                    toast.warning(errorMessage);
                    return;
                }

            const doc = editorViewRef.current.state.doc;
            let text = '';

            const processNode = (node, depth = 0) => {
                if (node.type.name === 'paragraph') {
                    text += formatParagraph(node) + '\n';
                } else if (node.type.name === 'ordered_list') {
                    node.forEach(listItem => {
                        listItem.content.forEach((childNode, i) => {
                            if (i === 0) {
                                text += '   '.repeat(depth) + '1. ' + formatParagraph(childNode) + '\n';
                            } else {
                                processNode(childNode, depth + 1);
                            }
                        });
                    });
                } else if (node.type.name === 'bullet_list') {
                    node.forEach(listItem => {
                        listItem.content.forEach((childNode, i) => {
                            if (i === 0) {
                                text += '   '.repeat(depth) + '- ' + formatParagraph(childNode) + '\n';
                            } else {
                                processNode(childNode, depth + 1);
                            }
                        });
                    });
                }
            };
    
            doc.forEach(node => {
                processNode(node);
            });

            text = text.trim();
            editorViewRef.current.dispatch(editorViewRef.current.state.tr.delete(0, editorViewRef.current.state.doc.content.size));

            // Parsing PDF files
            let pdfTexts = [];
            for (const pdf of attachedPdfs) {
                const pdfData = await pdf.arrayBuffer();
                const pdfDocument = await getDocument({ data: pdfData }).promise;
                let pdfText = '';
                for (let i = 1; i <= pdfDocument.numPages; i++) {
                    const page = await pdfDocument.getPage(i);
                    const content = await page.getTextContent();
                    pdfText += content.items.map(item => item.str).join(' ') + '\n';
                }
                pdfTexts.push(`PDF ${pdf.name}: ${pdfText}`);
            };

            // Parsing DOCX files
            let docxTexts = [];
            for (const docx of attachedDocx) {
                const arrayBuffer = await docx.arrayBuffer();
                const result = await mammoth.extractRawText({ arrayBuffer });
                docxTexts.push(`DOCX ${docx.name}: ${result.value}`);
            };

            // Parsing TXT files
            let txtTexts = [];
            for (const txt of attachedTxts) {
                const txtContent = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (event) => resolve(event.target.result);
                    reader.onerror = (error) => reject(error);
                    reader.readAsText(txt);
                });
                txtTexts.push(`TXT ${txt.name}: ${txtContent}`);
            }

            // Parsing CSV files
            let csvTexts = [];
            for (const csv of attachedCsvs) {
                const csvText = await new Promise((resolve, reject) => {
                  Papa.parse(csv, {
                    complete: (results) => {
                      resolve(`CSV ${csv.name}: ${JSON.stringify(results.data)}`);
                    },
                    error: (error) => {
                      reject(error);
                    }
                  });
                });
                csvTexts.push(csvText);
            }

            // Parsing XLSX files
            let xlsxTexts = [];
            for (const xlsx of attachedXlsx) {
                const ExcelJS = await import('exceljs');
                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(await xlsx.arrayBuffer());
                let content = '';
                workbook.eachSheet((worksheet, sheetId) => {
                    content += `Sheet ${sheetId}:\n`;
                    worksheet.eachRow((row, rowNumber) => {
                        
                        let rowContent = '';
                        // Using eachCell to iterate through each cell in the row
                        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                            let cellValue = '';

                            if (cell.value === null || cell.value === undefined) {
                                cellValue = ''; 
                            } else if (typeof cell.value === 'object') {
                                switch(cell.value.type) {
                                    case ExcelJS.ValueType.Number:
                                        cellValue = cell.value;
                                        break;
                                    case ExcelJS.ValueType.Error:
                                        cellValue = cell.value.error;
                                        break;
                                    case ExcelJS.ValueType.RichText:
                                        cellValue = cell.value.richText.map(textObj => textObj.text).join('');
                                        break;
                                    case ExcelJS.ValueType.Hyperlink:
                                        cellValue = cell.value.hyperlink;
                                        break;
                                    case ExcelJS.ValueType.Formula:
                                        cellValue = cell.value.result !== undefined ? cell.value.result : cell.value.formula;
                                        break;
                                    case ExcelJS.ValueType.String:
                                        cellValue = cell.value.text || '';
                                        break;
                                    case ExcelJS.ValueType.Merge:
                                        cellValue = cell.value.master ? `MERGED: ${cell.value.master.address}` : 'MERGED';
                                        break;
                                    case ExcelJS.ValueType.SharedString:
                                        cellValue = cell.value.text || '';
                                        break;
                                    case ExcelJS.ValueType.Boolean:
                                        cellValue = cell.value ? 'TRUE' : 'FALSE';
                                        break;
                                    case ExcelJS.ValueType.Date:
                                        cellValue = cell.value instanceof Date ? cell.value.toISOString() : '';
                                        break;
                                    default:
                                        cellValue = JSON.stringify(cell.value); // Fallback for unexpected types
                                }
                            } else {
                                cellValue = cell.value; // For primitive types like string, number, etc.
                            }
                            rowContent += `${cellValue},`;
                            });
                            rowContent = rowContent.replace(/,$/, '');
                            content += rowContent + '\n';
                    });
                    content += '\n';
                });
                xlsxTexts.push(`XLSX ${xlsx.name}: ${content}`);
            }

            let jsonTexts = [];
            for (const json of attachedJsons) {
                const jsonContent = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        try {
                            const parsedJson = JSON.parse(event.target.result);
                            resolve(`JSON ${json.name}: ${JSON.stringify(parsedJson)}`);
                        } catch (error) {
                            reject(`Error parsing JSON file ${json.name}: ${error.message}`);
                        }
                    };
                    reader.onerror = (error) => reject(`Error reading JSON file ${json.name}: ${error}`);
                    reader.readAsText(json);
                });
                jsonTexts.push(jsonContent);
            }

            const newMessage = {
                role: 'user',
                content: createMessageContent(text, uploadedImages, pdfTexts, docxTexts, csvTexts, txtTexts, xlsxTexts, jsonTexts),
                model: selectedModel
            };

            if (!firstMessageSent) {
                setFirstMessageSent(true);
            }

            const updatedMessages = [...messages, newMessage]
            setMessages(updatedMessages);

            // Clear attachments
            setUploadedImages([]);
            setAttachedPdfs([]);
            setAttachedDocx([]);
            setAttachedCsvs([]);
            setAttachedTxts([]);
            setAttachedXlsx([]);
            setAttachedJsons([]);

            const { conversationId: newConversationId } = await askAi(
                selectedModel, 
                updatedMessages, 
                setMessages, 
                text, 
                setStreamedResponse, 
                setStreaming, 
                user, 
                conversationId,
                setConversations,
                setCreditsBalance
            );

            if (newConversationId) {
                setConversationId(newConversationId);
            }

            // Update the conversations list
            const response = await fetch(`${process.env.REACT_APP_API_URL}/conversations/${user.sub}`);
            if (response.ok) {
                const updatedConversations = await response.json();
                setConversations(updatedConversations);
            }

        } catch (error) {
            console.error('handleSendMessage - Error sending message:', error);
            toast.error(`Seems like there is an issue on the ${selectedModel}'s side. Please try again or send a new message.`);
            setStreaming(false);
            
            // Remove the last user message from the messages array
            setMessages(prevMessages => prevMessages.slice(0, -1));
            
            // Don't clear the input field, allowing the user to retry or modify their message
        }
    };

    return (
        <div className="flex flex-col justify-end h-full w-full px-2 sm:px-0">
            <div 
                className="relative flex items-center w-[90%] mx-auto rounded-3xl border border-border bg-secondary dark:bg-secondary-dark bg-opacity-50 shadow-sm p-[2px_5px]"
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >
                <div
                    ref={promptEditor}
                    style={{ height: 'auto', marginBottom: '2px'}}
                    className={`w-full bg-transparent border-none px-4 text-text dark:text-text-dark resize-none overflow-y-auto h-10 max-h-[200px] leading-[2] focus:outline-none ${isDragging ? 'bg-primary bg-opacity-10' : ''}`}
                />
                {isDragging && (
                    <div className="absolute inset-0 flex items-center justify-center bg-primary bg-opacity-10 rounded-3xl pointer-events-none">
                        <p className="text-primary font-semibold">Drop files here</p>
                    </div>
                )}
                {attachedTxts.length > 0 && (
                    <div className="flex justify-center">
                        {attachedTxts.map((txt, index) => (
                            <div key={index} className="relative">
                                <span className="max-w-[30px] max-h-[30px] object-cover rounded border border-border mx-1 p-1 text-xs">
                                    TXT
                                </span>
                                <button 
                                    className="absolute -top-1 -right-1.5 bg-border text-white border-none rounded-full w-1.5 h-1.5 text-sm cursor-pointer flex items-center justify-center p-2 hover:bg-gray-500 transition-colors duration-200" 
                                    onClick={() => setAttachedTxts(prev => prev.filter((_, i) => i !== index))}
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                {attachedJsons.length > 0 && (
                    <div className="flex justify-center">
                        {attachedJsons.map((json, index) => (
                            <div key={index} className="relative">
                                <span className="max-w-[30px] max-h-[30px] object-cover rounded border border-border mx-1 p-1 text-xs">
                                    JSON
                                </span>
                                <button 
                                    className="absolute -top-1 -right-1.5 bg-border text-white border-none rounded-full w-1.5 h-1.5 text-sm cursor-pointer flex items-center justify-center p-2 hover:bg-gray-500 transition-colors duration-200" 
                                    onClick={() => setAttachedJsons(prev => prev.filter((_, i) => i !== index))}
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                {attachedCsvs.length > 0 && (
                    <div className="flex justify-center">
                        {attachedCsvs.map((csv, index) => (
                            <div key={index} className="relative">
                                <span className="max-w-[30px] max-h-[30px] object-cover rounded border border-border mx-1 p-1 text-xs">
                                    CSV
                                </span>
                                <button 
                                    className="absolute -top-1 -right-1.5 bg-border text-white border-none rounded-full w-1.5 h-1.5 text-sm cursor-pointer flex items-center justify-center p-2 hover:bg-gray-500 transition-colors duration-200" 
                                    onClick={() => setAttachedCsvs(prev => prev.filter((_, i) => i !== index))}
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                {attachedXlsx.length > 0 && (
                    <div className="flex justify-center">
                        {attachedXlsx.map((xlsx, index) => (
                            <div key={index} className="relative">
                                <span className="max-w-[30px] max-h-[30px] object-cover rounded border border-border mx-1 p-1 text-xs">
                                    XLSX
                                </span>
                                <button 
                                    className="absolute -top-1 -right-1.5 bg-border text-white border-none rounded-full w-1.5 h-1.5 text-sm cursor-pointer flex items-center justify-center p-2 hover:bg-gray-500 transition-colors duration-200" 
                                    onClick={() => setAttachedXlsx(prev => prev.filter((_, i) => i !== index))}
                                >
                                        ×
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                {attachedDocx.length > 0 && (
                    <div className="flex justify-center">
                        {attachedDocx.map((docx, index) => (
                            <div key={index} className="relative">
                                <span className="max-w-[30px] max-h-[30px] object-cover rounded border border-border mx-1 p-1 text-xs">
                                    DOCX
                                </span>
                                <button 
                                    className="absolute -top-1 -right-1.5 bg-border text-white border-none rounded-full w-1.5 h-1.5 text-sm cursor-pointer flex items-center justify-center p-2 hover:bg-gray-500 transition-colors duration-200" 
                                    onClick={() => setAttachedDocx(prev => prev.filter((_, i) => i !== index))}
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                )}  
                {attachedPdfs.length > 0 && (
                    <div className="flex justify-center">
                        {attachedPdfs.map((pdf, index) => (
                            <div key={index} className="relative">
                                <span className="max-w-[30px] max-h-[30px] object-cover rounded border border-border mx-1 p-1 text-xs">
                                    PDF
                                </span>
                                <button 
                                    className="absolute -top-1 -right-1.5 bg-border text-white border-none rounded-full w-1.5 h-1.5 text-sm cursor-pointer flex items-center justify-center p-2 hover:bg-gray-500 transition-colors duration-200" 
                                    onClick={() => setAttachedPdfs(prev => prev.filter((_, i) => i !== index))}
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                {uploadedImages.length > 0 && (
                    <div className="flex justify-center">
                        {uploadedImages.map((image, index) => (
                            <div key={index} className="relative">
                                <img key={index} src={image} alt={`Uploaded ${index}`} className="max-w-[30px] max-h-[30px] object-cover rounded border border-border mx-1" />
                                <button 
                                    className="absolute -top-1 -right-1.5 bg-border text-white border-none rounded-full w-1.5 h-1.5 text-sm cursor-pointer flex items-center justify-center p-2 hover:bg-gray-500 transition-colors duration-200" 
                                    onClick={() => setUploadedImages(prev => prev.filter((_, i) => i !== index))}
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                <input
                    type="file"
                    accept="image/png, image/jpeg, image/webp, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json"
                    onChange={(e) => handleFileInput(e.target.files)}
                    style={{ display: 'none' }}
                    id="fileUpload"
                    multiple
                />
                <div className="flex h-full items-end">
                    <Tooltip content="Attach docs and images (PDF, DOCX, TXT, CSV, XLSX, JSON, PNG, JPEG, WebP)">
                        <label 
                            htmlFor="fileUpload"
                            className="m-[5px] p-1 ml-2 cursor-pointer flex items-center justify-center w-8 h-8 flex-shrink-0 rounded-full transition-all duration-300 ease-in-out hover:bg-hover dark:hover:bg-hover-dark"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className={`w-5 h-5 ${darkMode ? 'text-secondary' : 'text-secondary-dark'}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                            </svg>
                        </label>
                    </Tooltip>
                </div>

                <div className="flex h-full items-end">
                    <button 
                        id="submit-button" 
                        onClick={handleSendMessage} 
                        disabled={streaming || input.trim() === ''} 
                        className="rounded-full border-none bg-primary m-[5px] p-1 disabled:bg-border disabled:opacity-60 hover:bg-primary-hover hover:scale-102 transition-all w-8 h-8 flex-shrink-0 flex items-center justify-center"
                        ref={submitButtonRef}
                    >
                        <img src="/arrow-icon.svg" alt="Arrow Icon" className="w-5 h-5" />
                    </button>
                </div>
            </div>
            <div className="flex w-full mx-auto justify-center p-1">
                <p className="text-xs text-gray-400 hidden sm:block">The models can make mistakes. Remember to check the answers</p>
            </div>
        </div>
    );
};

export default InputContainer;
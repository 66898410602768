import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { getModelInfo } from './ModelData';
import ModelButton from './ModelButton';
import ModelsPopup from './ModelsPopup';

const SwitchModelButton = ({ selectedModel, setSelectedModel, isMinimized, firstMessageSent, darkMode, visibleDetail, setVisibleDetail, showModelsPopup, setShowModelsPopup }) => {
    const modelInfo = useMemo(() => getModelInfo(darkMode), [darkMode]);

    const handleSwitch = (modelId) => {
        const selectedModelInfo = modelInfo.find(model => model.id === modelId);
        if (modelId === selectedModel) {
            toast.warning(`You're already chatting to ${selectedModelInfo ? selectedModelInfo.name : 'this model'}.`);
        } else {
            setSelectedModel(modelId);
            toast.success(`Switched to ${selectedModelInfo ? selectedModelInfo.name : 'the selected model'}`);
        }
        setShowModelsPopup(false);
    };

    return (
        <div className="relative">
            <button 
                className={`p-2 rounded-full transition-colors text-sm ${
                    isMinimized 
                        ? 'w-10' 
                        : 'w-44 text-left'
                } ${firstMessageSent ? 'hover:bg-background dark:hover:bg-hover-dark' : 'opacity-50 '}`}
                onClick={() => setShowModelsPopup(!showModelsPopup)}
                aria-label="Switch model"
                disabled={!firstMessageSent}
            >
                <div className={`flex items-center ${isMinimized ? 'justify-center' : 'justify-start'}`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 flex-shrink-0"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#7cb342"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M2 18 L11 18 L13 6 L20 6 L18 3 L23 6 L18 9" />
                    </svg>
                    {!isMinimized && <span className="ml-2 text-text dark:text-text-dark">Switch model</span>}
                </div>
            </button>
            {showModelsPopup && (
                <ModelsPopup 
                    onClose={() => setShowModelsPopup(false)}
                    visibleDetail={visibleDetail}
                    setVisibleDetail={setVisibleDetail}
                    darkMode={darkMode}
                    selectedModel={selectedModel}
                    setSelectedModel={handleSwitch}
                >
                    <ModelButton
                        isPopup={true}
                    />
                </ModelsPopup>
            )}
        </div>
    );
};

export default SwitchModelButton;
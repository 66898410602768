import React, { useState, useEffect, useMemo } from 'react';
import Tooltip from './Tooltip';
import { getModelInfo } from './ModelData';
import ModelButton from './ModelButton';
import ModelsPopup from './ModelsPopup';

const ModelOptions = ({ selectedModel, setSelectedModel, darkMode, visibleDetail, setVisibleDetail, user}) => {
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const modelInfo = useMemo(() => getModelInfo(darkMode), [darkMode]);
    const [mainOptions, setMainOptions] = useState([]);

    useEffect(() => {
        const fetchRecentModels = async () => {
            try {
                const userId = user.sub;
                const response = await fetch(`${process.env.REACT_APP_API_URL}/models/${userId}`);

                if (!response.ok) {
                    const text = await response.text();
                    console.error('Error response body:', text);
                    throw new Error(`Failed to fetch recent models: ${response.status} ${response.statusText}`);
                }
                
                const data = await response.json();
                const recentModels = data.models;
                
                // Map recent models to full model info
                const recentModelInfo = recentModels.map(modelId => 
                    modelInfo.find(model => model.id === modelId)
                ).filter(Boolean);

                // Fill remaining slots with default models if needed
                while (recentModelInfo.length < 3) {
                    const defaultModel = modelInfo.find(model => !recentModelInfo.includes(model));
                    if (defaultModel) {
                        recentModelInfo.push(defaultModel);
                    } else {
                        break;
                    }
                }
                setMainOptions(recentModelInfo);

                // Set selectedModel to the first model in mainOptions
                if (recentModelInfo.length > 0) {
                    setSelectedModel(recentModelInfo[0].id);
                }

            } catch (error) {
                console.error('Error fetching recent models:', error);
                setMainOptions(modelInfo.slice(0, 3));
            }
        };

        fetchRecentModels();
    }, [user.sub, modelInfo, setSelectedModel]);

    useEffect(() => {
        if (selectedModel && !mainOptions.some(model => model.id === selectedModel)) {
            const selectedModelInfo = modelInfo.find(model => model.id === selectedModel);
            if (selectedModelInfo) {
                const newMainOptions = [
                    selectedModelInfo,
                    ...mainOptions.filter(model => model.id !== selectedModel).slice(0, 2)
                ];
                setMainOptions(newMainOptions);
            }
        }
    }, [selectedModel, mainOptions, modelInfo]);
  
    return (
        <div className="flex flex-col items-center w-full p-2 space-y-2">
            <div className="flex flex-col sm:flex-row items-center justify-center w-full space-y-2 sm:space-y-0 sm:space-x-2">
                {mainOptions.map((model) => (
                    // Use a media query to disable tooltips on small screens
                    <Tooltip key={model.id} content={model} detailed>
                        <ModelButton
                            model={model}
                            selectedModel={selectedModel}
                            setSelectedModel={setSelectedModel}
                            isMain={true}
                        />
                    </Tooltip>
                ))}
                <div className="relative">
                    <div 
                        className="flex items-center justify-center bg-none hover:bg-hover dark:hover:bg-hover-dark transition-colors duration-300 p-3 rounded-full cursor-pointer w-full h-9"
                        onClick={() => setShowMoreOptions(!showMoreOptions)}
                    >
                        <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M12 3l1.5 3.5L17 8l-3.5 1.5L12 13l-1.5-3.5L7 8l3.5-1.5L12 3z"/>
                            <path d="M5 17l1 2 2 1-2 1-1 2-1-2-2-1 2-1 1-2z"/>
                            <path d="M18 17l1 2 2 1-2 1-1 2-1-2-2-1 2-1 1-2z"/>
                        </svg>
                        <p className="text-sm">More</p> 
                    </div>
                    {showMoreOptions && (
                        <ModelsPopup 
                            onClose={() => setShowMoreOptions(false)}
                            visibleDetail={visibleDetail}
                            setVisibleDetail={setVisibleDetail}
                            darkMode={darkMode}
                            selectedModel={selectedModel}
                            setSelectedModel={(id) => {
                                setSelectedModel(id);
                                setShowMoreOptions(false);
                            }}
                        >
                            <ModelButton
                                isPopup={true}
                            />
                        </ModelsPopup>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ModelOptions;
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { formatMessages } from '../utils/messageUtils';
import EditTitlePopup from './EditTitlePopup';
import ClearConversationPopup from './ClearConversationPopup';
import ConversationOptionsPopup from './ConversationOptionsPopup';
import { toast } from 'react-toastify';

const ChatHistory = ({ user, setMessages, setFirstMessageSent, conversations, setConversations, conversationId, setConversationId, setSelectedModel }) => {
    const { isAuthenticated } = useAuth0();
    const [conversationPopup, setConversationPopup] = useState(null);
    const [editingConversation, setEditingConversation] = useState(null);
    const [clearingConversation, setClearingConversation] = useState(null);
    const [starredConversations, setStarredConversations] = useState([]);
    const [nonStarredConversations, setNonStarredConversations] = useState([]);
    const popupRef = useRef(null);

    const updateConversationLists = (updatedConversation) => {
        setConversations(prevConversations => {
            const newConversations = prevConversations.map(conv => 
                conv.conversation_id === updatedConversation.conversation_id ? updatedConversation : conv
            );
            const starred = newConversations.filter(conv => conv.starred);
            const nonStarred = newConversations.filter(conv => !conv.starred);
            setStarredConversations(starred);
            setNonStarredConversations(nonStarred);
            return newConversations;
        });
    };
  
    useEffect(() => {
        const fetchConversations = async () => {
            if (!isAuthenticated || !user) {
                console.log('User not authenticated or user data not available');
                return;
            }
    
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/conversations/${user.sub}`);

                if (response.ok) {
                    const data = await response.json();
                    const starred = data.filter(conv => conv.starred);
                    const nonStarred = data.filter(conv => !conv.starred);
                    setStarredConversations(starred);
                    setNonStarredConversations(nonStarred);
                    setConversations(data);
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error('Error fetching conversations:', error);
                toast.error('Oops, we were unable to fetch your conversations. Please try again later.');
            }
        };
    
        fetchConversations();
    }, [isAuthenticated, user, setConversations ]);

    useEffect(() => {
        const starred = conversations.filter(conv => conv.starred);
        const nonStarred = conversations.filter(conv => !conv.starred);
        setStarredConversations(starred);
        setNonStarredConversations(nonStarred);
    }, [conversations]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setConversationPopup(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchMessages = async (clickedConversationId) => {
        try {
            setConversationId(clickedConversationId);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/conversation/${clickedConversationId}`);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const formattedMessages = formatMessages(data);
            setMessages(formattedMessages);

            setSelectedModel(formattedMessages[formattedMessages.length - 1].model);
            setFirstMessageSent(true);
        } catch (error) {
            console.error('Error fetching conversation messages:', error);
        }
    };

    const groupedConversations = useMemo(() => {
        const now = new Date();
        const today = now.setHours(0, 0, 0, 0);
        const weekAgo = new Date(today - 7 * 24 * 60 * 60 * 1000);

        return nonStarredConversations.reduce((acc, conv) => {
            const convDate = new Date(conv.updated_at);
            let key;
            if (convDate >= today) {
                key = 'Today';
            } else if (convDate >= weekAgo) {
                key = 'Last 7 Days';
            } else {
                key = 'Older';
            }
            (acc[key] = acc[key] || []).push(conv);
            return acc;
        }, {});
    }, [nonStarredConversations]);

    const handleEditTitle = (conversation) => {
        setEditingConversation(conversation);
        setConversationPopup(null);
    };

    const handleTitleSubmit = async (editedTitle, editedTitleConversationId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/conversations/${editedTitleConversationId}/title`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ title: editedTitle }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const updatedConversation = await response.json();

            updateConversationLists(updatedConversation);
            toast.success('Conversation title updated successfully');
    
        } catch (error) {
            console.error('Error updating conversation title:', error);
            toast.error('Oops, something went wrong. Please try again.');
        }

        setEditingConversation(null);
    };

    const handleClearConversation = (conversation) => {
        setClearingConversation(conversation);
        setConversationPopup(null);
    };

    const clearConversation = async () => {
        if (!clearingConversation) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/conversations/${clearingConversation.conversation_id}/clear`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ action: 'clear_messages' }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setConversations(prevConversations => {
                const newConversations = prevConversations.filter(conv => conv.conversation_id !== clearingConversation.conversation_id);
                const starred = newConversations.filter(conv => conv.starred);
                const nonStarred = newConversations.filter(conv => !conv.starred);
                setStarredConversations(starred);
                setNonStarredConversations(nonStarred);
                return newConversations;
            });

            if (conversationId === clearingConversation.conversation_id) {
                setConversationId(null);
                setMessages([]);
                setFirstMessageSent(false);
            }

            toast.success('Conversation cleared successfully');
        } catch (error) {
            console.error('Error clearing conversation:', error);
            toast.error('Oops, something went wrong. Please try again.');
        }

        setClearingConversation(null);
    };

    const handleStarConversation = async (conversation) => {
        setConversationPopup(null);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/conversations/${conversation.conversation_id}/star`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const updatedConversation = await response.json();
            updateConversationLists(updatedConversation);

        } catch (error) {
            console.error('Error starring conversation:', error);
            toast.error('Oops, something went wrong. Please try again.');
        }
    };
    
    const renderConversationList = (conversations, sectionTitle = null) => (
        <div>
          {sectionTitle && (
            <h4 className="text-xs font-semibold text-gray-500 dark:text-gray-400 mt-2 mb-2 border-b border-gray-200 dark:border-gray-800 pb-1 flex items-center">
              {sectionTitle === 'Starred' && (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                </svg>
              )}
              {sectionTitle}
            </h4>
          )}
          <ul className="space-y-1 mb-4">
            {conversations.map((conversation) => (
                <li key={conversation.conversation_id} className="relative group">
                    <button 
                        className={`w-full text-left px-2 py-2 rounded-lg transition-colors duration-200 group-hover:bg-white dark:group-hover:bg-gray-700 ${
                            conversation.conversation_id === conversationId
                      ? 'bg-white dark:bg-gray-700'
                            : 'bg-secondary dark:bg-secondary-dark'
                        }`}
                        onClick={() => fetchMessages(conversation.conversation_id)}
                    >
                        <p className="text-xs text-text dark:text-text-dark truncate pr-8">
                            {conversation.title}
                        </p>
                    </button>
                    <button
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 rounded-lg transition-colors duration-200 opacity-0 group-hover:opacity-100"
                        onClick={(e) => {
                            e.stopPropagation();
                            setConversationPopup(conversationPopup === conversation.conversation_id ? null : conversation.conversation_id);
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                        </svg>
                    </button>
                    {conversationPopup === conversation.conversation_id && (
                        <div ref={popupRef} className="absolute right-0 top-0 mt-8 w-52 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 z-50">
                            <ConversationOptionsPopup
                                onStar={() => handleStarConversation(conversation)}
                                onEdit={() => handleEditTitle(conversation)}
                                onClear={() => handleClearConversation(conversation)}
                                isStarred={conversation.starred}
                            />
                        </div>
                    )}
                </li>
            ))}
          </ul>
        </div>
    );

    return (
        <div className="flex flex-col h-full">
          <h3 className="text-sm font-semibold px-4 py-2 text-text dark:text-text-dark">Your conversations</h3>
          <div className="flex-1 overflow-y-auto px-4 mb-8">
            {starredConversations.length > 0 && renderConversationList(starredConversations, 'Starred')}
            {Object.entries(groupedConversations).map(([section, convs]) => 
                convs.length > 0 && (
                    <React.Fragment key={section}>
                        {renderConversationList(convs, section)}
                    </React.Fragment>
                )
            )}
          </div>
          {editingConversation && (
                <EditTitlePopup
                    conversation={editingConversation}
                    onClose={() => setEditingConversation(null)}
                    onSubmit={handleTitleSubmit}
                />
            )}
            {clearingConversation && (
                <ClearConversationPopup
                    onClose={() => setClearingConversation(null)}
                    onClear={clearConversation}
                />
            )}
        </div>
      );
  };

export default ChatHistory;
import React, { useMemo } from 'react';
import ChatMessage from './ChatMessage';
import { getModelInfo } from './ModelData';

const ChatArea = ({ user, selectedModel, messages, chatEnd, streamedResponse, streaming, darkMode }) => {
  const modelInfo = useMemo(() => getModelInfo(darkMode), [darkMode]);

  const getModelDetails = (modelId) => 
    modelInfo.find(model => model.id === modelId) || {};

  const initialModel = messages.length > 0 ? getModelDetails(messages[0].model) : getModelDetails(selectedModel);

  return (
    <div className="flex flex-col justify-start items-center flex-1 overflow-y-auto w-full scroll-smooth bg-background dark:bg-background-dark">
      <div className="w-full sm:w-5/6 max-w-3xl">
        {messages.length > 0 && (
          // Display which model the chat was started with
          <div className="text-center justify-center py-2 text-sm text-text dark:text-text-dark max-w-[95%] mx-3">
            Started chat with {initialModel.logo && <img src={initialModel.logo} alt={initialModel.name} className="inline-block h-4 w-4 pb-0.5" />} <strong>{initialModel.name}</strong>, developed by <strong>{initialModel.developer}</strong>
          </div>
        )}
        {/* Display each finalized (non-streaming) message in the chat */}
        {messages.map((message, i) => (
          <React.Fragment key={i}>
            {i > 0 && message.model && messages[i - 1].model && message.model !== messages[i - 1].model && (
              <div className={`text-center py-2 my-2 text-sm ${darkMode ? 'text-text-dark' : 'text-text'} border-t border-border`}>
                <p>Switched to {getModelDetails(message.model).logo && <img src={getModelDetails(message.model).logo} alt={getModelDetails(message.model).name} className="inline-block h-4 w-4 pb-0.5" />} <strong>{getModelDetails(message.model).name}</strong>, developed by <strong>{getModelDetails(message.model).developer}</strong></p>
              </div>
            )}
            <ChatMessage 
              selectedModel={message.model}
              message={message} 
              user={user}
              streamedResponse={streamedResponse}
              darkMode={darkMode}
            />
          </React.Fragment>
        ))}
        {/* Display the streamed response in the chat */}
        {streaming && (
          <ChatMessage 
            key={messages.length} 
            selectedModel={selectedModel}
            message={{ role: 'assistant', content: streamedResponse }} 
            user={user}
            streamedResponse={streamedResponse}
            darkMode={darkMode}
          />
        )}
        <div ref={chatEnd}/>
      </div>
    </div>
  );
};

export default ChatArea;
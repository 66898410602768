import React from 'react';

const DarkModeToggle = ({ darkMode, toggleDarkMode }) => {

  // Render dark mode toggle button
  return (
    <div
      onClick={toggleDarkMode}
      className="w-24 h-8 bg-gray-300 rounded-md p-1 cursor-pointer relative"
    >
      <div 
        className={`w-10 h-6 bg-white rounded-md absolute transition-transform duration-300 ${
          darkMode ? 'translate-x-12' : 'translate-x-0'
        }`}
      />
      <div className="absolute inset-0 flex items-center justify-between px-2">
        <span className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-800'}`}>Light</span>
        <span className={`text-sm ${darkMode ? 'text-gray-800' : 'text-gray-400'}`}>Dark</span>
      </div>
    </div>
  );
};

export default DarkModeToggle;
import React from 'react';
import AuthButton from './AuthButton';
import Logo from './Logo';

function LoginPage() {
    return (
        <div className="h-screen w-full flex items-center justify-center">
            <div className='flex flex-col items-center justify-center w-4/5 max-w-2xl mx-auto h-[60vh] bg-secondary dark:bg-secondary-dark rounded-[50px] border border-border shadow-md p-5'>
                <div className='mb-5'>
                    <Logo />
                </div>
                <h1 className="text-4xl font-bold mb-8 dark:text-text-dark">Strawberry</h1>
                <h3 className="text-xl italic mb-8 text-center dark:text-text-dark"><em>Better AI conversations</em></h3>
                <br />
                <AuthButton />
                <br />
            </div>
        </div>
    );
}

export default LoginPage;
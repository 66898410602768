import React from 'react';
import CapabilityBadges from './CapabilityBadges';
import ModelScoreBarChart from './ModelScoreBarChart';

const ModelButton = ({ model, selectedModel, setSelectedModel, isPopup = false, isDetail, toggleDetail, isMain = false}) => (
    <div 
        className={`flex ${isPopup ? 'flex-col' : 'items-center justify-center'} bg-none hover:bg-hover dark:hover:bg-hover-dark ${isMain ? 'sm:border sm:border-border' : 'border border-border'} ${isPopup ? 'p-5 my-3' : 'p-3'} ${isPopup ? 'rounded-lg' : 'rounded-full'} cursor-pointer w-full ${isPopup ? 'h-auto' : 'h-9'} ${isMain ? 'sm:shadow-sm' : 'shadow-sm'} ${
            selectedModel === model.id ? 'border-primary text-primary bg-primary-light dark:bg-primary-dark' : ''} hover:bg-hover dark:hover:bg-hover-dark transition-colors duration-300`}
        onClick={() => setSelectedModel(model.id)}
    >
        <div className="flex items-center justify-between">
            <div className="flex items-center" onClick={() => setSelectedModel(model.id)}>
                <img className="h-5 mr-2 rounded-md" src={model.logo} alt={`${model.name} Logo`} />
                <p className={`text-sm flex ${isPopup ? 'font-semibold' : 'font-normal'}`}>{model.name}</p>
            </div>
            {isPopup && (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleDetail();
                    }}
                    className="text-sm text-primary hover:text-primary-dark p-1 rounded-full transition-all duration-300 ease-in-out hover:bg-white border-border border"
                >
                    <svg
                        className="w-4 h-4"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#7cb342"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        {isDetail ? (
                            <path d="M18 15l-6-6-6 6" />
                        ) : (
                            <path d="M6 9l6 6 6-6" />
                        )}
                    </svg>
                </button>
            )}
        </div>
        {isPopup && (
            <>
                <p className="text-xs mt-1 text-text dark:text-text-dark">{model.description}</p>
                <hr className="my-2 border-border dark:border-border-dark" />
                <p className="text-xs mt-1 text-text dark:text-text-dark flex items-center">
                    <svg className="w-3 h-3 mr-1" viewBox="0 0 24 24" fill="currentColor">
                        <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2"/>
                        <text x="12" y="17" fontFamily="Arial" fontSize="14" textAnchor="middle" fill="currentColor">€</text>
                    </svg>
                    Cost: {model.cost} credits
                    <span className="mx-2">•</span>
                    <svg className="w-3 h-3 mr-1" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M13 10V3L4 14h7v7l9-11h-7z"/>
                    </svg>
                    Speed: {model.speed}/10
                </p>
                {isDetail && (
                    <>
                        <hr className="my-2 border-border dark:border-border-dark" />
                        <CapabilityBadges model={model} />
                        <hr className="my-2 border-border dark:border-border-dark" />
                        <ModelScoreBarChart scores={model.scores} />
                    </>
                )}
            </>
        )}
    </div>
);

export default ModelButton;